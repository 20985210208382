<template>
    <router-link :to="{ name: 'articleDetail', params: { slug: article.slug } }">
        <div class="last-article mt-5">
            <div class="row gx-5">
                <div class="col-12 col-md-6 mb-4">
                    <div class="aspect-ratio-box">
                        <img :src="article.image_url">
                    </div>
                </div>
                <div class="col-12 col-md-6 d-flex align-items-center">
                    <div class="content"> 
                        <div class="meta mb-3">
                            <span>{{ article.author }}</span>
                            <span class="dot">•</span>
                            <span>{{ formatDate(article.publication_date) }}</span>
                        </div>
                        <div class="title mb-3">
                            {{ article.title }}
                        </div>
                        <div class="article-content">
                            <p v-html="trunContent(article.content)"></p>
                        </div>
                        <div class="ert mt-3">
                            <p>{{ estimatedReadTime(article.content) }} min read</p>
                        </div>
                    </div>                   
                </div>
            </div>
        </div>
    </router-link>
</template>

<script>
import moment from 'moment';

export default {
    name: 'LastArticle',
    props: {
        article: {
            type: Object,
            required: true
        }
    },
    methods: {
        trunContent(content) {
            const maxLength = 240;
            return content.length > maxLength ? content.substring(0, maxLength) + '...' : content;
        },
        formatDate(date) {
            return moment(date).format('MM.DD.YYYY');
        },
        estimatedReadTime(content) {
            const words = content.split(/\s+/).length;
            const wordsPerMinute = 100;
            const readTime = Math.ceil(words / wordsPerMinute);
            return readTime;
        }
    }
}
</script>

<style scoped>
    .last-article img {
        width: 100%;
    }

    .aspect-ratio-box {
        display: grid;
        place-items: center;
        width: 100%;
        aspect-ratio: 4 / 3;
        overflow: hidden;
    }

    .aspect-ratio-box img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 1em;
    }

    .content {
        text-align: start;
    }

    .meta {
        display: flex;
        align-items: center;
        font-size: 1rem;
        font-weight: 500;
    }

    .meta .dot {
        margin: 0 0.5em;
        font-size: 0.875rem;
        line-height: 0.875rem;
    }

    .title {
        font-family: 'Lora', sans-serif;
        font-size: 42px;
        font-weight: 600;
    }

    .article-content {
        font-size: 16px;
        font-weight: 500;
        color: #606060;
    }

    .ert {
        font-size: 16px;
        font-weight: 500;
    }

    @media (max-width: 768px) {
        .meta {
            font-size: 0.7em;
        }

        .title {
            font-size: 1.9em;
        }

        .article-content {
            font-size: 0.8em;
        }

        .ert {
            font-size: 0.7em;
        }
    }
</style>