<template>
    <cookie-allow v-if="isCookieShow" @close="closeCookie" />

    <navbar />

    <router-view/>

    <footer-block />
</template>

<script>
import Navbar from '@/components/Navbar.vue'
import CookieAllow from '@/components/CookieAllow.vue'
import FooterBlock from './components/FooterBlock.vue'

export default {
    name: 'App',
    components: {
        Navbar, CookieAllow, FooterBlock
    },
    data() {
        return {
            isCookieShow: true
        }
    },
    mounted() {
        this.checkCookie();
    },  
    methods: {
        closeCookie() {
            this.isCookieShow = false
        },
        checkCookie() {
            if ($cookies.get('isCookie')) {
                this.isCookieShow = false
            }
        }
    },  
    computed: {
        domainName() {
            return this.$domainInfo.name;
        }
    }
}
</script>

<style>
    @import url('https://fonts.googleapis.com/css2?family=Lora:ital,wght@0,400..700;1,400..700&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

    #app {
        font-family: 'Roboto', sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-align: center;
        color: #0f0f0f;
    }

    a {
        text-decoration: none;
        color: inherit;
    }

    .nav div.links a {
        font-weight: 400;
        font-size: 12px;
        color: #0f0f0f;
        text-decoration: none;
        text-transform: uppercase;
    }

    .nav div.links a.router-link-exact-active {
        font-weight: 600;
    }

    div.logo a {
        font-weight: 500;
        text-transform: none;
        text-decoration: none;
        color: #0f0f0f;
    }

    footer {
        font-size: 14px;
        padding: 40px;
    }

    /* Main Styles */

    .heading-small {
        font-weight: 500;
        font-size: 32px;
    }

    .label-small {
        color: #909090;
        text-transform: uppercase;
        font-size: 14px;
    }

    .paragraph-small {
        font-size: 14px;
        line-height: 2em;
    }

    .paragraph-large {
        font-size: 16px;
        line-height: 2em;
        font-weight: 400;
    }

    .title-block {
        width: 70%;
        margin: 0 auto;
        margin-top: 20px;
        margin-bottom: 50px;
    }

    @media (max-width: 768px) {
        .title-block {
            width: 100%;
        }
    }

    /* Buttons */

    .dark-button-large {
        border: 0;
        background: #0f0f0f;
        color: white;
        text-transform: uppercase;
        font-size: 18px;
        font-weight: 500;
        padding: 20px 30px;
    }

    .dark-button-large:hover {
        background: #2c2c2c;
    }

    .dark-button {
        border: 0;
        background: #0f0f0f;
        color: white;
        text-transform: uppercase;
        font-size: 14px;
        font-weight: 500;
        padding: 10px 20px;
    }

    .dark-button:hover {
        background: #2c2c2c;
    }

    .content p img {
        border-radius: 1em;
        width: 100%;
    }
</style>
