<template>
    <div class="container">
        <div v-if="news && news.length">
            <div class="row">
                <div v-for="article in news" :key="article.id" class="col-12 col-md-6">
                    <full-article :article="article" />
                </div>
            </div>
        </div>
        <div v-else>
            <p>Loading...</p>
        </div>
    </div>
</template>

<script>
import axiosInstance from '@/plugins/axios';
import FullArticle from '@/components/FullArticle.vue'

export default {
    name: 'BlogView',
    components: { FullArticle },
    data() {
        return {
            news: [],
            site: null
        };
    },
    async mounted() {
        this.site = this.domainSite;
        this.fetchNewsByPage();
        this.updateMetaTags();
    },
    methods: {
        async fetchNewsByPage() {
            try {
                const response = await axiosInstance.post('/get-news', { site: this.site });
                this.news = response.data.data;
                this.totalPages = response.data.last_page;
            } catch (error) {
                console.log(this.domainSite)
                console.error('Error fetching news:', error);
            }
        },
        trunContent(content) {
            const maxLength = 180;
            return content.length > maxLength ? content.substring(0, maxLength) + '...' : content;
        },
        updateMetaTags() {
            document.title = `${this.domainName} | Blog`;
            this.setMetaTag('description', `${this.domainName} | Check our articles!`);
            this.setMetaTag('keywords', this.domainName);
            this.setMetaTag('robots', 'index, follow');
        },
        setMetaTag(name, content) {
            let element = document.querySelector(`meta[name="${name}"]`);
            if (!element) {
                element = document.createElement('meta');
                element.setAttribute('name', name);
                document.head.appendChild(element);
            }
            element.setAttribute('content', content);
        }
    },
    computed: {
        domainSite() {
            return this.$domainInfo.site;
        },
        domainName() {
            return this.$domainInfo.name;
        }
    }
}
</script>

<style scoped>
    
</style>
