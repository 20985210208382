<template>
    <footer>
        <div class="container my-5">
            <div class="row row-reverse">
                <div class="col-6 col-md-2">
                    <div class="subtitle">
                        Our office:
                    </div>
                    <div>
                        5697 Veum Cove, <br>
                        West Stephenie, <br>
                        MS 25959
                    </div>
                </div>
                <div class="col-6 col-md-2 mb-4">
                    <div class="mb-2">
                        <div class="subtitle">
                            We are working:
                        </div>
                        <div>
                            9AM - 5PM
                        </div>
                    </div>
                    <div>
                        <div class="subtitle">
                            Contact:
                        </div>
                        <div>
                            info@{{ domain }}
                        </div>
                    </div>
                </div>
                <div class="col-6 col-md-2">

                </div>
                <div class="col-6 col-md-2">

                </div>
                <div class="col-12 col-md-4">
                    <div class="footer-data">
                        <div class="logo">
                            {{ domainName }}
                        </div>
                        <div class="small-label mt-3">
                            Craft narratives that ignite insparation, knowledge, and entertaiment.
                        </div>

                        <div class="copyright mt-5">
                            Copyright © 2024 {{ domainName }}.
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
export default {
    name: 'FooterBlock',
    computed: {
        domainName() {
            return this.$domainInfo.name;
        },
        domain() {
            return window.location.hostname;
        }
    }
}
</script>

<style scoped>
    footer {
        text-align: start;
    }

    .footer-data {
        text-align: end;
    }

    .subtitle {
        font-size: 16px;
        font-weight: 500;
    }

    .logo {
        text-decoration: none;
        color: #DF0000;
        font-size: 20px;
        font-weight: 700;
        font-family: 'Lora', sans-serif;
    }

    .small-label {
        font-size: 14px;
        font-weight: 500;
        color: #606060;
    }

    .first-block {
        text-align: start;
        width: 70%;
    }

    .second-block {
        text-align: start;
        padding-right: 60px;
    }

    .copyright {
        font-size: 14px;
        font-weight: 500;
        color: #606060;
    }

    @media (max-width: 768px) {
        .footer-data {
            text-align: start;
        }
    }
</style>