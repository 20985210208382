<template>
    <div class="col-6 col-md-3 mb-4">
        <router-link :to="{ name: 'articleDetail', params: { slug: article.slug } }">
            <div class="article">
                <div class="aspect-ratio-box">
                    <img :src="article.image_url" alt="Article Image">
                </div>
                <div class="meta d-flex justify-content-between mt-2">
                    <span>{{ article.author }}</span>
                    <span>{{ formatDate(article.publication_date) }}</span>
                </div>
                <div class="title mt-2">
                    {{ article.title }}
                </div>
                <div class="article-content mt-2">
                    <p v-html="trunContent(article.content)"></p>
                </div>
            </div>
        </router-link>
    </div>
</template>

<script>
import moment from 'moment';

export default {
    name: 'SmallArticle',
    props: {
        article: {
            type: Object,
            required: true
        }
    },
    methods: {
        trunContent(content) {
            const maxLength = 180;
            return content.length > maxLength ? content.substring(0, maxLength) + '...' : content;
        },
        formatDate(date) {
            return moment(date).format('MM.DD.YYYY');
        }
    }
}
</script>

<style scoped>
    .aspect-ratio-box {
        display: grid;
        place-items: center;
        width: 100%;
        aspect-ratio: 1 / 1;
        overflow: hidden;
    }

    .aspect-ratio-box img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 1em;
    }

    .article {
        text-align: start;
    }

    .meta {
        display: flex;
        align-items: center;
        font-size: 0.875rem;
        font-weight: 500;
    }

    .meta .dot {
        margin: 0 0.5em;
        font-size: 0.875rem;
        line-height: 0.875rem;
    }

    .title {
        font-family: 'Lora', sans-serif;
        font-size: 20px;
        font-weight: 600;
    }

    .article-content {
        font-size: 14px;
        font-weight: 500;
        color: #606060;
    }

    @media (max-width: 768px) {
        .meta {
            font-size: 0.7em;
        }

        .title {
            font-size: 1em;
        }

        .article-content {
            font-size: 0.7em;
        }

        .ert {
            font-size: 0.7em;
        }
    }
</style>