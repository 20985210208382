<template>
    <div class="cookie-consent-container">
        <div class="cookie-consent-content">
            <div class="cookie-text">
                We use cookies to enhance your browsing experience, serve personalized ads or content, and analyze our traffic.
            </div>
            <div class="cookie-buttons">
                <button class="accept-button" @click.prevent="closeCookie">Accept All</button>
                <button class="reject-button" @click.prevent="closeCookie">Reject</button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'CookieAllow',
    methods: {
        closeCookie() {
            $cookies.set('isCookie' , true, "24h");
            this.$emit('close');
        },
    }
}
</script>

<style scoped>
.cookie-consent-container {
    position: fixed;
    bottom: 20px;
    left: 20px;
    right: 20px;
    background-color: #f9f9f9;
    border: 1px solid #606060;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    z-index: 1000;
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: 'Roboto', sans-serif;
    color: #333;
}

.cookie-consent-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.cookie-text {
    margin-bottom: 15px;
    text-align: center;
    font-weight: 500;
}

.cookie-policy-link {
    color: #df0000;
    text-decoration: none;
    font-weight: bold;
}

.cookie-policy-link:hover {
    text-decoration: underline;
}

.cookie-buttons {
    display: flex;
    justify-content: space-around;
    width: 100%;
}

.accept-button,
.reject-button {
    background-color: #df0000;
    color: white;
    border: none;
    padding: 10px 20px;
    margin: 5px;
    cursor: pointer;
    border-radius: 5px;
    width: 100%;
    font-size: 14px;
}

.reject-button {
    background-color: #333;
}

.accept-button:hover,
.reject-button:hover {
    opacity: 0.8;
}

@media (max-width: 768px) {
    .cookie-consent-container {
        flex-direction: column;
        padding: 15px;
        bottom: 10px;
        left: 10px;
        right: 10px;
    }
    .cookie-text {
        margin-bottom: 10px;
        text-align: center;
    }
    .cookie-buttons {
        flex-direction: column;
    }
    .accept-button,
    .reject-button {
        width: 100%;
        margin: 5px 0;
    }
}
</style>
