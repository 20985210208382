<template>
    <div class="container">
        <div class="creator-info">
            <div class="welcome-block">
                <div class="main-label">
                    Become a Creator on {{ domainName }}
                </div>
                <div class="description mt-3">
                    We are always looking for passionate writers who can share unique insights and captivating stories. If you want to join our community of creators, fill out the form below and let us know more about you and your work.
                </div>
            </div>
        </div>
        <div class="form-section mt-5">
            <div class="form-container">
                <form @submit.prevent="submitForm">
                    <div class="form-group">
                        <input type="text" v-model="form.name" placeholder="Your Name" required>
                    </div>
                    <div class="form-group">
                        <input type="email" v-model="form.email" placeholder="Your Email" required>
                    </div>
                    <div class="form-group">
                        <textarea v-model="form.desc" placeholder="Tell us about yourself" required></textarea>
                    </div>
                    <button type="submit" class="btn btn-primary">Submit</button>
                </form>

                <div class="mt-5" v-if="isSumbited">
                    <p>Thank you! We will contact you via email.</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Creator',
    data() {
        return {
            form: {
                name: null,
                email: null,
                desc: null
            },
            isSumbited: false,
        }
    },  
    methods: {
        submitForm() {
            this.form.name = null
            this.form.email = null
            this.form.desc = null

            this.isSumbited = true
        }
    },
    computed: {
        domainName() {
            return this.$domainInfo.name;
        }
    }
}
</script>

<style scoped>
    .container {
        padding: 20px;
    }

    .welcome-block {
        background-color: #F5F5F5;
        border-radius: 1em;
        padding: 50px;
        text-align: center;
    }

    .main-label {
        font-size: 13px;
        text-transform: uppercase;
        letter-spacing: 5px;
        font-weight: 600;
        color: #606060;
    }

    .description {
        font-size: 22px;
        font-weight: 600;
        width: 100%;
        margin: 0 auto;
    }

    .form-section {
        margin-top: 50px;
    }

    .form-container {
        max-width: 600px;
        margin: 0 auto;
        background-color: #ffffff;
        padding: 30px;
        border-radius: 1em;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    }

    .form-group {
        margin-bottom: 20px;
    }

    form input {
        border: 0;
        padding: 14px 20px;
        border-radius: .75em;
        width: 100%;
        border: 2px solid #606060;
    }

    form input:focus {
        outline: none;
        border: 2px solid #DF0000;
    }

    form textarea {
        border: 0;
        padding: 14px 20px;
        border-radius: .75em;
        width: 100%;
        border: 2px solid #606060;
    }

    form textarea:focus {
        outline: none;
        border: 2px solid #DF0000;
    }

    .btn {
        cursor: pointer;
        border: 0;
        padding: 14px 20px;
        border-radius: .75em;
        width: 100%;
        color: white;
        background-color: #DF0000;
    }

    .btn:active {
        background-color: #b10000;
    }

    @media (max-width: 768px) {
        .welcome-block {
            padding: 20px;
        }

        .main-label {
            font-size: 16px;
        }

        .description {
            font-size: 16px;
        }

        .form-container {
            padding: 20px;
        }

        .form-control {
            font-size: 14px;
        }

        .btn {
            font-size: 16px;
            padding: 10px;
        }
    }
</style>
