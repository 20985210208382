<template>
    <header class="nav-container container">
        <div class="logo">
            <router-link to="/" class="logo-link">
                <span>{{ domainName }}</span>
            </router-link>
        </div>
        <div class="divider"></div>
        <nav :class="['nav-links', { active: menuOpen }]">
            <router-link to="/blog" @click.prevent="menuOff" class="nav-item">Blog</router-link>
            <router-link to="/creator" @click.prevent="menuOff" class="nav-item">Creator</router-link>
            <router-link to="/community" @click.prevent="menuOff" class="nav-item">Community</router-link>
            <router-link to="/subscribe" @click.prevent="menuOff" class="nav-item">Subscribe</router-link>
        </nav>
        <div :class="['burger', { open: menuOpen }]" @click="toggleMenu">
            <div class="burger-line"></div>
            <div class="burger-line"></div>
            <div class="burger-line"></div>
        </div>
    </header>
</template>

<script>
export default {
    name: 'Navbar',
    data() {
        return {
            menuOpen: false,
        };
    },
    methods: {
        toggleMenu() {
            this.menuOpen = !this.menuOpen;
        },
        menuOff() {
            this.menuOpen = false;
        }
    },
    computed: {
        domainName() {
            return this.$domainInfo.name;
        }
    }
};
</script>

<style scoped>
.nav-container {
    display: flex;
    align-items: center;
    position: relative;
    padding: 20px 20px;
    background-color: #fff;
}

.logo-link {
    text-decoration: none;
    color: #DF0000;
    font-size: 20px;
    font-weight: 700;
    font-family: 'Lora', serif;
}

.divider {
    height: 20px;
    width: 1.5px;
    background-color: #b7b7b7;
    margin-left: 20px;
    margin-right: 10px;
}

.nav-links {
    display: flex;
    flex-grow: 1;
    justify-content: flex-start;
    align-items: center;
    font-family: 'Roboto', sans-serif;
    font-weight: 300;
    font-size: 16px;
    color: #0f0f0f;
}

.nav-item {
    margin: 0 10px;
    text-decoration: none;
    color: inherit;
    transition: color 0.3s ease;
}

.nav-item:hover {
    color: #DF0000;
}

.nav-links a.router-link-exact-active {
    font-weight: 500;
    color: #DF0000;
}

.burger {
    display: none;
    flex-direction: column;
    justify-content: space-around;
    width: 24px;
    height: 24px;
    cursor: pointer;
    z-index: 2;
}

.burger-line {
    width: 24px;
    height: 3px;
    background-color: #0f0f0f;
    border-radius: 5px;
    transition: all 0.3s ease;
}

.burger.open .burger-line:nth-child(1) {
    transform: translateY(9px) rotate(45deg);
}

.burger.open .burger-line:nth-child(2) {
    opacity: 0;
}

.burger.open .burger-line:nth-child(3) {
    transform: translateY(-9px) rotate(-45deg);
}

@media (max-width: 768px) {
    .nav-container {
        padding: 10px 20px;
    }

    .nav-links {
        position: absolute;
        top: 100%;
        left: 0;
        right: 0;
        flex-direction: column;
        background-color: #fff;
        opacity: 0;
        pointer-events: none;
        transition: opacity 0.3s ease;
        z-index: 2;
    }

    .nav-links.active {
        opacity: 1;
        pointer-events: auto;
    }

    .nav-item {
        margin: 1rem 0;
    }

    .burger {
        display: flex;
        margin-left: auto;
    }

    .divider {
        display: none;
    }
}
</style>
