<template>
    <div class="container">
        <div class="welcome-block">
            <div class="small-label mb-2">
                Welcome to {{ domainName }}
            </div>
            <div class="main-label" style="">
                Craft narratives ✍🏻 that ignite <span class="highlight-red">insparation</span> 💡, <span class="highlight-red">knowledge</span> 📕, and <span class="highlight-red">entertaiment</span> 🎬
            </div>
        </div>

        <div v-if="firstArticle">
            <last-article :article="firstArticle" />
        </div>
        <div v-else class="mt-5">
            <p>Loading...</p>
        </div>

        <div class="mt-5">
            <div class="labels mb-3">
                <div class="label">Latest News</div>
                <div class="link-all">
                    <router-link to="/blog">
                        See all →
                    </router-link>
                </div>
            </div>
            <div class="row">
                <small-article v-for="article in latestArticles" :key="article.slug" :article="article" />
            </div>
        </div>

        <!-- <div class="mt-5">
            <div class="labels mb-3">
                <div class="label">Editor's Pick</div>
                <div class="link-all">
                    <router-link to="/blog">
                        See all →
                    </router-link>
                </div>
            </div>
            <div class="row">
                <tiny-article :article="article" />
                <tiny-article :article="article" />
                <tiny-article :article="article" />
                <tiny-article :article="article" />
            </div>
        </div> -->

        <subscribe-block class="mt-5" />
    </div>
</template>

<script>
import axiosInstance from '@/plugins/axios'
import LastArticle from '@/components/LastArticle.vue'
import SmallArticle from '@/components/SmallArticle.vue'
import TinyArticle from '@/components/TinyArticle.vue'
import SubscribeBlock from '@/components/SubscribeBlock.vue'

export default {
    name: 'HomeView',
    components: {
        LastArticle, SmallArticle, TinyArticle, SubscribeBlock
    },
    data() {
        return {
            articles: [],
            site: null
        }
    },
    mounted() {
        this.site = this.domainSite
        this.fetchArticles()
        this.updateMetaTags()
    },
    methods: {
        async fetchArticles() {
            try {
                const response = await axiosInstance.post('/get-news', { site: this.site });
                this.articles = response.data.data;
            } catch (error) {
                console.error('Error fetching news:', error);
            }
        },
        updateMetaTags() {
            document.title = `${this.domainName}`;
            this.setMetaTag('description', `${this.domainName} | Craft narratives ✍🏻 that ignite insparation 💡, knowledge 📕, and entertaiment 🎬`);
            this.setMetaTag('keywords', this.domainName);
            this.setMetaTag('robots', 'index, follow');
        },
        setMetaTag(name, content) {
            let element = document.querySelector(`meta[name="${name}"]`);
            if (!element) {
                element = document.createElement('meta');
                element.setAttribute('name', name);
                document.head.appendChild(element);
            }
            element.setAttribute('content', content);
        }
    },
    computed: {
        firstArticle() {
            return this.articles.length > 0 ? this.articles[0] : null;
        },
        latestArticles() {
            return this.articles.slice(1, 5);
        },
        domainSite() {
            return this.$domainInfo.site;
        },
        domainName() {
            return this.$domainInfo.name;
        }
    }
}
</script>

<style scoped>
    .content {
        margin-top: 50px;
        text-align: start;
    }

    .welcome-block {
        background-color: #F5F5F5;
        border-radius: 1em;
        padding: 50px;
    }

    .small-label {
        font-size: 13px;
        text-transform: uppercase;
        letter-spacing: 5px;
        font-weight: 600;
        color: #606060;
    }

    .main-label {
        font-size: 28px;
        font-weight: 600;
        width: 50%;
        margin: 0 auto;
    }

    .highlight-red {
        color: #DF0000;
    }

    .labels {
        text-align: start;
        display: flex;
        justify-content: space-between;
        font-size: 28px;
        font-weight: 600;
    }

    .label {
        font-size: 32px;
        font-weight: 500;
    }
    
    .link-all {
        font-size: 20px;
        font-weight: 500;
        color: #DF0000;
        margin-top: 8px;
    }

    @media (max-width: 768px) {
        .welcome-block {
            padding: 20px;
        }

        .small-label {
            font-size: 0.5em;
        }

        .main-label {
            font-size: 0.9em;
            width: 90%;
        }
    }
</style>
