<template>
    <div class="col-6 col-md-3 mb-4">
        <router-link>
            <div class="article">
                <div class="aspect-ratio-box">
                    <img :src="article.imageURL" alt="Article Image">
                </div>
                <div class="meta d-flex justify-content-between mt-2">
                    <span>{{ article.author }}</span>
                    <span>{{ formatDate(article.publication_date) }}</span>
                </div>
                <div class="title mt-2">
                    {{ trunContent(article.title) }}
                </div>
            </div>
        </router-link>
    </div>
</template>

<script>
import moment from 'moment';

export default {
    name: 'TinyArticle',
    props: {
        article: {
            type: Object,
            required: true
        }
    },
    methods: {
        trunContent(content) {
            const maxLength = 50;
            return content.length > maxLength ? content.substring(0, maxLength) + '...' : content;
        },
        formatDate(date) {
            return moment(date).format('MM.DD.YYYY');
        }
    }
}
</script>

<style scoped>
    .aspect-ratio-box {
        display: grid;
        place-items: center;
        width: 100%;
        aspect-ratio: 1 / 1;
        overflow: hidden;
    }

    .aspect-ratio-box img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 1em;
    }

    .article {
        text-align: start;
    }

    .meta {
        display: flex;
        align-items: center;
        font-size: 0.875rem;
        font-weight: 500;
    }

    .title {
        font-family: 'Lora', sans-serif;
        font-size: 22px;
        font-weight: 600;
    }

    @media (max-width: 768px) {
        .meta {
            font-size: 0.7em;
        }

        .title {
            font-size: 1em;
        }
    }
</style>