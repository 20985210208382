import { createRouter, createWebHistory } from 'vue-router'

import HomeView from '@/views/HomeView.vue'
import BlogView from '@/views/BlogView.vue'
import ArticleDetailView from '@/views/ArticleDetailView.vue'
import CreatorView from '@/views/CreatorView.vue'
import CommunityView from '@/views/CommunityView.vue'
import SubscribeView from '@/views/SubscribeView.vue'

const routes = [
    {
        path: '/',
        name: 'home',
        component: HomeView,
        meta: { title: 'Home - Title' }
    },
    {
        path: '/blog',
        name: 'blog',
        component: BlogView,
        meta: { title: 'Blog - Title' }
    },
    {
        path: '/creator',
        name: 'creator',
        component: CreatorView,
        meta: { title: 'Creator - Title' }
    },
    {
        path: '/community',
        name: 'community',
        component: CommunityView,
        meta: { title: 'Community - Title' }
    },
    {
        path: '/subscribe',
        name: 'subscribe',
        component: SubscribeView,
        meta: { title: 'Subscribe - Title' }
    },
    {
        path: '/news/:slug',
        name: 'articleDetail',
        component: ArticleDetailView,
        props: true,
        meta: { title: 'Article - Title' }
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router
