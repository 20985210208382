<template>
    <div class="container py-5">
        <div class="welcome-block">
            <div class="small-label mb-2">
                Subscribe to Our Newsletter 📨
            </div>
            <div class="main-label">
                Stay ahead of the curve by subscribing to our newsletter. Get the latest news, updates, and insights delivered right to your inbox. 🚀
            </div>
        </div>
    
        <subscribe-block />
    </div>
</template>
  
<script>
import SubscribeBlock from '@/components/SubscribeBlock.vue';

export default {
    name: 'SubscribeView',
    components: {
        SubscribeBlock
    },
    data() {
        return {
            email: null,
        }
    },
    methods: {
        subscribe() {
            this.email = null
            alert('Thank you for subscribing! 🎉 We will keep you updated with the latest news.')
        }
    }
}
</script>
  
<style scoped>
.container {
    padding: 20px;
}
  
.welcome-block {
    background-color: #F5F5F5;
    border-radius: 1em;
    padding: 50px;
    margin-bottom: 30px;
}
  
.small-label {
    font-size: 13px;
    text-transform: uppercase;
    letter-spacing: 5px;
    font-weight: 600;
    color: #606060;
}

.main-label {
    font-size: 28px;
    font-weight: 600;
    width: 80%;
    margin: 0 auto;
}
  
.subscribe-block {
    background-color: #F5F5F5;
    border-radius: 1em;
    padding: 50px;
}
  
.highlight-red {
    color: #DF0000;
}
  
.row {
    margin-left: 0;
    margin-right: 0;
}
  
.col {
    padding-left: 0;
    padding-right: 0;
}
  
.subscribe-input {
    border: 0;
    padding: 14px 20px;
    border-radius: .75em;
    width: 60%;
    border: 2px solid #606060;
}
  
.subscribe-input:focus {
    outline: none;
    border: 2px solid #DF0000;
}
  
.subscribe-button {
    cursor: pointer;
    border: 0;
    padding: 14px 20px;
    border-radius: .75em;
    color: white;
    background-color: #DF0000;
}
  
.subscribe-button:active {
    background-color: #b10000;
}

@media (max-width: 768px) {
    .small-label {
        font-size: 0.8em;
    }

    .main-label {
        font-size: 1.5em;
    }
}
</style>
  