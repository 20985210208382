<template>
    <div class="container">
        <div class="welcome-block">
            <div class="small-label mb-2">
                Welcome to the {{ domainName }} Community 🚀
            </div>
            <div class="main-label">
                Join our community to stay updated on <span class="highlight-red">cryptocurrency</span> 💰, <span class="highlight-red">blockchain</span> 🔗, and <span class="highlight-red">innovation</span> ⚡
            </div>
        </div>

        <div class="welcome-block mt-5">
            <div class="small-label mb-2">
                About Us 🏢
            </div>
            <div class="main-label">
                At {{ domainName }}, we are dedicated to bringing you the latest and most reliable news in the world of cryptocurrency. Our mission is to provide insightful and up-to-date information to help you navigate the ever-evolving digital economy.
            </div>
        </div>

        <div class="welcome-block mt-5">
            <div class="small-label mb-2">
                Our Community 🌐
            </div>
            <div class="main-label">
                Our community is made up of enthusiasts, investors, and professionals who are passionate about cryptocurrency and blockchain technology. Together, we share knowledge, insights, and the latest trends.
            </div>
        </div>

        <div class="welcome-block mt-5">
            <div class="small-label mb-2">
                How to Join 🤝
            </div>
            <div class="main-label">
                It's easy to become a part of our community. Follow us on social media, subscribe to our newsletter, and join our events. Together, we explore and shape the future of digital finance.
            </div>
            <div class="join-links">
                <router-link to="/subscribe">
                    <button>Subscribe to Newsletter 📨</button>
                </router-link>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'CommunityView',
    computed: {
        domainName() {
            return this.$domainInfo.name;
        }
    }
}
</script>

<style scoped>
.container {
    padding: 20px;
}

.welcome-block {
    background-color: #F5F5F5;
    border-radius: 1em;
    padding: 50px;
    text-align: center;
    margin-bottom: 30px;
}

.small-label {
    font-size: 13px;
    text-transform: uppercase;
    letter-spacing: 5px;
    font-weight: 600;
    color: #606060;
}

.main-label {
    font-size: 28px;
    font-weight: 600;
    width: 80%;
    margin: 0 auto;
}

.highlight-red {
    color: #DF0000;
}

.join-links {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-top: 20px;
}

.join-links button {
    cursor: pointer;
    border: 0;
    padding: 14px 20px;
    border-radius: .75em;
    color: white;
    background-color: #DF0000;
}

.join-links button:active {
    background-color: #b10000;
}

.join-links .btn {
    padding: 15px;
    font-size: 18px;
    font-weight: 600;
    color: #fff;
    text-decoration: none;
    border-radius: 1em;
}

.btn-primary {
    background-color: #DF0000;
}

@media (max-width: 768px) {
    .welcome-block {
        padding: 20px;
    }

    .small-label {
        font-size: 0.7em;
    }

    .main-label {
        font-size: 0.9em;
        width: 100%;
    }

    .community-images {
        flex-direction: column;
    }

    .community-image {
        width: 100%;
    }

    .join-links {
        flex-direction: column;
    }

    .join-links .btn {
        width: 100%;
    }
}
</style>
