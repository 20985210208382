<template>
    <router-link :to="{ name: 'articleDetail', params: { slug: article.slug } }">
        <div class="article-card my-4">
            <div class="article-content">
                <div class="image-container" :style="{ backgroundImage: 'url(' + article.image_url + ')' }">
                    <div class="overlay">
                        <div class="text-content p-3">
                            <div class="meta">
                                <span>{{ article.author }}</span>
                                <span class="dot">•</span>
                                <span>{{ formatDate(article.publication_date) }}</span>
                            </div>
                            <div class="title">
                                {{ article.title }}
                            </div>
                            <div class="article-summary">
                                <p v-html="trunContent(article.content)"></p>
                            </div>
                            <div class="ert">
                                <p>{{ estimatedReadTime(article.content) }} min read</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </router-link>
</template>

<script>
import moment from 'moment';

export default {
    name: 'ArticleCard',
    props: {
        article: {
            type: Object,
            required: true
        }
    },
    methods: {
        trunContent(content) {
            const maxLength = 120;
            return content.length > maxLength ? content.substring(0, maxLength) + '...' : content;
        },
        formatDate(date) {
            return moment(date).format('MM.DD.YYYY');
        },
        estimatedReadTime(content) {
            const words = content.split(/\s+/).length;
            const wordsPerMinute = 100;
            const readTime = Math.ceil(words / wordsPerMinute);
            return readTime;
        }
    }
}
</script>

<style scoped>
.article-card {
    position: relative;
    overflow: hidden;
    border-radius: 1em;
    background-color: #f0f0f0;
}

.image-container {
    position: relative;
    width: 100%;
    padding-bottom: 56.25%;
    background-size: cover;
    background-position: center;
    border-radius: 0.5em;
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: flex-end;
    border-radius: 0.5em;
}

.overlay:hover {
    background: rgba(128, 0, 0, 0.8);
    transition: all 0.6s ease;
}

.text-content {
    color: #ffffff;
    width: 100%;
    text-align: start;
}

.meta {
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 500;
}

.meta .dot {
    margin: 0 0.5em;
    font-size: 14px;
    line-height: 0.875rem;
}

.title {
    font-family: 'Lora', serif;
    font-size: 28px;
    font-weight: 700;
}

.article-summary {
    font-family: 'Lora', serif;
    font-size: 16px;
    font-weight: 400;
    white-space: normal;
    overflow-wrap: break-word;
    max-width: 100%;
}

.ert {
    font-size: 14px;
    font-weight: 500;
}

@media (max-width: 768px) {
    .meta {
        font-size: 0.8em;
    }

    .title {
        font-size: 1.4em;
    }

    .article-summary {
        font-size: 0.8em;
    }

    .ert {
        display: none;
    }
}
</style>

