import { createApp } from 'vue';
import { createMetaManager } from 'vue-meta';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import 'typeface-montserrat';
import VueCookies from 'vue-cookies';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { faClock } from '@fortawesome/free-solid-svg-icons';
import { faPen } from '@fortawesome/free-solid-svg-icons';
import BootstrapVue from 'bootstrap/dist/css/bootstrap.css';
import App from './App.vue';
import router from './router';
import store from './store';
import axios from './plugins/axios';
import config from './config/config';

library.add(faUser, faClock, faPen);

router.afterEach((to) => {
    const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);
    if (nearestWithTitle) document.title = nearestWithTitle.meta.title;
});

const app = createApp(App);

async function fetchDomainInfo() {
    try {
        const domainName = window.location.hostname;
        const response = await axios.post('/get-domain', { domain: domainName });

        config.domainInfo = {
            domain: response.data.domain.domain,
            name: response.data.domain.name,
            site: response.data.domain.site
        };
    } catch (error) {
        // console.error('Error fetching domain info:', error);
    }
}

const metaManager = createMetaManager();

fetchDomainInfo().then(() => {
    app.config.globalProperties.$domainInfo = config.domainInfo;
    app.use(store).use(BootstrapVue).use(router).use(metaManager).component('font-awesome-icon', FontAwesomeIcon).mount('#app');
});