<template>
    <div class="block">
        <div class="row d-flex align-items-center">
            <div class="col-12 col-md-6 mb-4">
                <div class="small-label mb-2">
                    Get first update
                </div>
                <div class="main-label">
                    Get the news in front line by <span class="highlight-red">subscribe</span> ✍🏻 our latest updates
                </div>
            </div>
            <div class="col-12 col-md-6 justify-content-center">
                <div>
                    <form @submit.prevent="subscribe">
                        <div class="row">
                            <div class="col-12 col-md-8 mb-4">
                                <input type="email" v-model="email" class="subscribe-input" placeholder="Your email" required>
                            </div>
                            <div class="col-12 col-md-4">
                                <button type="submit" class="subscribe-button">Subscribe</button>
                            </div>
                        </div>
                        <!-- <div class="d-flex">
                            <input type="email" v-model="email" class="subscribe-input me-3" placeholder="Your email" required>
                            <button type="submit" class="subscribe-button">Subscribe</button>
                        </div> -->
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'SubscribeBlock',
    data() {
        return {
            email: null,
        }
    },
    methods: {
        subscribe() {
            this.email = null
            alert('Thank you! We will notice you by email.')
        }
    }
}
</script>

<style scoped>
    .block {
        background-color: #F5F5F5;
        border-radius: 1em;
        padding: 50px;
    }

    .small-label {
        text-align: start;
        font-size: 14px;
        text-transform: uppercase;
        letter-spacing: 5px;
        font-weight: 600;
        color: #606060;
    }

    .main-label {
        text-align: start;
        font-size: 34px;
        font-weight: 600;
        width: 90%;
    }

    .highlight-red {
        color: #DF0000;
    }

    form {
        width: 100%;
    }

    .subscribe-input {
        border: 0;
        padding: 14px 20px;
        border-radius: .75em;
        width: 100%;
        border: 2px solid #606060;
    }

    .subscribe-input:focus {
        outline: none;
        border: 2px solid #DF0000;
    }

    .subscribe-button {
        cursor: pointer;
        border: 0;
        padding: 14px 20px;
        border-radius: .75em;
        color: white;
        width: 100%;
        background-color: #DF0000;
    }

    .subscribe-button:active {
        background-color: #b10000;
    }

    @media (max-width: 768px) {
        .small-label {
            font-size: 0.8em;
        }

        .main-label {
            font-size: 1.5em;
        }
    }
</style>