<template>
    <div class="container">
        <div v-if="article" class="article">
            <img class="main-image" v-if="article.image_url" :src="article.image_url" :alt="article.keyword">
            <div class="title-article">
                <div class="heading-article">
                    {{ article.title }}
                </div>
            </div>
            <div class="content" v-html="article.content"></div>
            <div class="metadata d-flex justify-content-between">
                <div class="d-flex">
                    <p>{{ formatDate(article.publication_date) }}</p>
                </div>
                <div class="d-flex">
                    <p>{{ article.author }}</p>
                </div>
            </div>
        </div>
        <div v-else>
            <p>Loading...</p>
        </div>
    </div>
</template>

<script>
import axiosInstance from '@/plugins/axios';
import moment from 'moment';

export default {
    name: 'ArticleDetailView',
    props: ['slug'],
    components: {  },
    data() {
        return {
            article: null
        };
    },
    computed: {
        formattedDate() {
            if (!this.article || !this.article.publication_date) return '';
            const options = { year: 'numeric', month: 'long', day: 'numeric' };
            return new Date(this.article.publication_date).toLocaleDateString(undefined, options);
        }
    },
    async mounted() {
        await this.fetchArticle();
        this.updateMetaTags();
    },
    methods: {
        async fetchArticle() {
            try {
                const response = await axiosInstance.get(`/get-article/${this.slug}`);
                this.article = response.data;
            } catch (error) {
                console.error('Error fetching article:', error);
                this.article = null;
            }
        },
        updateMetaTags() {
            if (this.article) {
                document.title = `${this.article.title} | ${this.domainName}`;
                this.setMetaTag('description', this.article.content);
                this.setMetaTag('keywords', this.article.keyword);
                this.setMetaTag('robots', 'index, follow');
            }
        },
        setMetaTag(name, content) {
            let element = document.querySelector(`meta[name="${name}"]`);
            if (!element) {
                element = document.createElement('meta');
                element.setAttribute('name', name);
                document.head.appendChild(element);
            }
            element.setAttribute('content', content);
        },
        formatDate(date) {
            return moment(date).format('MM.DD.YYYY');
        }
    },
    computed: {
        domainName() {
            return this.$domainInfo.name;
        },
    }
}
</script>

<style scoped>
    .main-image {
        max-width: 60%;
        height: auto;
        margin-bottom: 10px;
        border-radius: 1em;
        margin-top: 40px;
    }

    .article .metadata {
        width: 60%;
        margin: 0 auto;
        margin-bottom: 10px;
        font-family: 'Lora', sans-serif;
        font-weight: 500;
    }

    .article .content {
        text-align: start;
        width: 60%;
        margin: 0 auto;
    }

    .title-article {
        width: 60%;
        margin: 0 auto;
        margin-bottom: 20px;
        text-align: start;
        font-family: 'Lora', sans-serif;
    }

    .heading-article {
        font-weight: 500;
        font-size: 32px;
    }

    @media (max-width: 768px) {
        .article img {
            max-width: 100%;
            margin-bottom: 30px;
        }

        .article .metadata {
            width: 100%;
            margin-bottom: 10px;
        }

        .article .content {
            width: 100%;
        }

        .title-article {
            width: 100%;
        }

        .heading-article {
            font-size: 24px;
        }
    }
</style>
